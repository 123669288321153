export default [
    {
        name: 'litho',
        type: 'gltfModel',
        path: 'models/litho.glb',
    },
    {
        name: 'HDRI',
        type: 'hdrTexture',
        path: 'images/environmentMap/autoshop.hdr',
    },
];
