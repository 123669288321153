export default [
    {
        name: 'hello',
        type: 'gltfModel',
        path: 'models/hello.glb',
    },
    {
        name: 'hello_tex',
        type: 'texture',
        path: 'images/models/hello.png',
    },
];
